import React, { useState, useEffect } from 'react';

const Timer = () => {
    const [totalSeconds, setTotalSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTotalSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    return (
        <div>
            <h2>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}</h2>
        </div>
    );
};

export default Timer;
