import React, { useEffect, useState } from "react";
import "./App.css";
import Timer from "./Timer";

const App = () => {
  const ethers = require("ethers");
  const provider = new ethers.providers.JsonRpcProvider(
    "https://chain.ozonescan.com/"
  );
  const [privateKeyA, setPrimateKeyA] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [working, setWorking] = useState(false)
  let x = 0;
  // useEffect(() => {
  //   if (x === 0) {
  //    GetBalance();
  //     x++;
  //   }
  // }, []) ;
  function GetBalance() {
    try {
      if (privateKeyA.length === 66 && receiverAddress.length === 42 && senderAddress.length === 42) {
        setWorking(true)
        const wallet = new ethers.Wallet(privateKeyA, provider);
        const interval = setInterval(async () => {
          const balance = await provider.getBalance(senderAddress);
          const balanceInEther = ethers.utils.formatEther(balance);
          console.log("balance", balanceInEther);
          if (balanceInEther > 0.000021) {
            let newBal = (balanceInEther - 0.000021).toFixed(18);
            let amount = ethers.utils.parseEther(newBal);
            sendTransaction(amount);
          }
        }, 2000);
      } else {
        alert("Please check your details")
      }
    } catch (e) {
      alert("Something went wrong")
    }
  }

  async function sendTransaction(amount) {
    const wallet = new ethers.Wallet(privateKeyA, provider);
    const transactionOptions = {
      to: receiverAddress,
      value: amount,
      gasLimit: 21000,
      gasPrice: ethers.utils.parseUnits("1", "gwei"),
      chainId: 1131, // Replace with the appropriate chain ID
    };
    try {
      const transaction = await wallet.sendTransaction(transactionOptions);
      console.log("Transaction successful:", transaction);
    } catch (error) {
      console.error("Error transferring funds:", error);
    }
  }

  return (
    <div >
      <p>Sender Private key</p>
      <input type="text" value={privateKeyA} onChange={(e) => setPrimateKeyA(e.target.value)} />
      <p className="note">Note: Add 0x before private key</p>
      <p >Sender Address</p>
      <input type="text" value={senderAddress} onChange={(e) => setSenderAddress(e.target.value)} />
      <p>Receiver Address</p>
      <input type="text" value={receiverAddress} onChange={(e) => setReceiverAddress(e.target.value)} />
      {
        working && <Timer />
      }
      <button onClick={GetBalance}>Start</button>
    </div>
  );
};

export default App;

//  async function GetBalance() {
//    setInterval(async function () {
//      const balance = await provider.getBalance(
//        "0xCebF034F76865479f7276577aC27d3086b1C9e68"
//      );
//      const balanceInEther = ethers.utils.formatEther(balance);
//      console.log("balance", balanceInEther);
//      if (balanceInEther > 0.000022) {
//        let newBal = (balanceInEther - 0.000022).toFixed(6);
//        let amount = ethers.utils.parseEther(newBal);
//        sendTransaction(amount);
//      }
//    }, 2000);
//  }
